import axios from "axios";
import env from "@/env";
import "@/api/requests_config";


export default {
    login(credentials) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/login", {
                    email: credentials.email,
                    password: credentials.password,
                })
                .then((res) => {
                    const user = res.data;
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    register(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/register", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    forgetPassword(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/forget-password", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    checkOTP(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/update-password-with-otp", data)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    setNewPassword(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/new-password", data.data ,{
                    headers: {
                        'Authorization': `Bearer ${data.token}`, // Include the token in the Authorization header
                    }})
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    updatePassword(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(env.DEVELOPMENT_DOMAIN + "/auth/update-password", data.data ,{
                    headers: {
                        'Authorization': `Bearer ${data.token}`, // Include the token in the Authorization header
                    }})
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
}