import API from '@/api/payment_api';

const state = {
    payload: null,
};

const getters = {

};

const actions = {
    // eslint-disable-next-line no-unused-vars
    submitEmail({commit}, data) {
        return new Promise((resolve, reject) => {
            API.submitEmail(data).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    completePayment({commit} , data){
        console.log(data);
        return new Promise((resolve, reject) => {
            API.completePayment(data).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    checkPromoCode({commit} , data){
        return new Promise((resolve, reject) => {
            API.checkPromoCode(data).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}