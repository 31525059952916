import API from "@/api/payment_api";

const state = {
    packageDetails: null,
};

const getters = {
    packageDetails(state){
        return state.packageDetails
    },

};

const actions = {
    // eslint-disable-next-line no-unused-vars
    packageDetails({commit}, data) {
        commit('packageDetails' , data)
    },
    // eslint-disable-next-line no-unused-vars
    cancelSubscription({commit}) {
        return new Promise((resolve, reject) => {
            API.cancelSubscription().then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },

};

const mutations = {
    packageDetails(state,payload){
        state.packageDetails = payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}