import Vuex from "vuex";

// MODULES
import payment from "@/store/modules/payment";
import auth from "@/store/modules/auth"
import checkout from "@/store/modules/check_out";
import package_store from "@/store/modules/package";
import blogs from "@/store/modules/blogs";
import account from "@/store/modules/account";

export default new Vuex.Store({
    modules: {
        payment,
        auth,
        checkout,
        package_store,
        blogs,
        account
    },
})
