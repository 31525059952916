import axios from "axios";
import env from "@/env";
import "@/api/requests_config";

export default {
    submitForm(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/personal-information/store` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    MeetingDate(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/reservation_appointment/store` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

};
