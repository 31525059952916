import {createHead} from "@vueuse/head";
import VueGtagEsm from "vue-gtag";
import { createApp } from 'vue'
import router from "@/router";
import App from './App.vue'
import store from "./store/store";
import axios from "axios";


import "./index.css"
import './registerServiceWorker'
// import {VueReCaptcha} from "vue-recaptcha-v3";
// VUE ROUTE PORGRESS BAR
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "left",
  inverse: false,
};


const app = createApp(App);
const head = createHead();

app.use(
  VueGtagEsm,
  {
    appName:'Artefy',
    pageTrackerScreenviewEnabled: true,
    config: {id:'G-YCBR8SDP0T'}
  },
  router
);
app.config.globalProperties.axios = axios;
app.component('VueDatePicker', VueDatePicker);

app.use(head)
// app.use(VueReCaptcha,{siteKey:'6LcdQVooAAAAAKx7WK3EgxWsVVRtRZJuAixeztX4'});
app.use(VueProgressBar, options).use(router).use(store).mount("#app");