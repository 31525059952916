import axios from "axios";

axios.interceptors.request.use(
    function (config) {
        const user = JSON.parse(sessionStorage.getItem("user"));
        if (user != null) {
            config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);
