import axios from "axios";
import env from "@/env";
import "@/api/requests_config";

export default {
    submitEmail(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/subscribe_newsletters/store` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    completePayment(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/stripe/create-payment-method` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    cancelSubscription(){
        return new Promise((resolve, reject) => {
            axios
                .get(`${env.DEVELOPMENT_DOMAIN}/stripe/canceled`)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    checkPromoCode(data) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/promo-code/check` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },

};
