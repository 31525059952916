import axios from "axios";
import env from "@/env";
import "@/api/requests_config";

export default {
    getBlogs() {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/blogs/all`)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    homeBlogs() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${env.DEVELOPMENT_DOMAIN}/blogs/featured`)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    showBlog(slug) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${env.DEVELOPMENT_DOMAIN}/blogs/all/${slug}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};
