import auth_api from "@/api/auth_api";

const state = {
    user: null,
    email:null,
    token:null,
    saveRegToken:null
};

const getters = {
    getUserDetails(state) {
        return state.user;
    },
    getSavedEmail(state){
        return state.email
    },
    getSavedToken(state){
        return state.token
    },
    getSaveRegToken(state){
        return state.saveRegToken
    }

};

const actions = {
    login({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            auth_api.login(credentials)
                .then((user) => {
                    commit("loginSuccess", user);
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    register({ commit }, data) {
        return new Promise((resolve, reject) => {
            auth_api.register(data)
                .then((user) => {
                    commit('saveRegToken' , user.data.data.token)
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    forgetPassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            auth_api.forgetPassword(data)
                .then((user) => {
                    commit('saveEmail',data)
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    checkOTP({ commit }, data) {
        return new Promise((resolve, reject) => {
            auth_api.checkOTP(data)
                .then((user) => {
                    commit('saveToken',user.data.data.token )
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    setNewPassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            auth_api.setNewPassword(data)
                .then((user) => {
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    updatePassword({ commit }, data) {
        return new Promise((resolve, reject) => {
            auth_api.updatePassword(data)
                .then((user) => {
                    resolve(user);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    setTokenNull({ commit }) {
        commit('saveToken' , null)
    },
};

const mutations = {
    loginSuccess(state, payload) {
        state.user = payload;
    },
    saveEmail(state, payload){
        state.email = payload.email
    },
    saveToken(state, payload){
        state.token = payload
    },
    saveRegToken(state , payload){
        state.saveRegToken = payload
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
