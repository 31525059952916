import API from '@/api/blogs_api';

const state = {

};

const getters = {

};

const actions = {
    getBlogs() {
        return new Promise((resolve, reject) => {
            API.getBlogs().then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    homeBlogs() {
        return new Promise((resolve, reject) => {
            API.homeBlogs().then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    showBlog({commit}, slug) {
        return new Promise((resolve, reject) => {
            API.showBlog(slug).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}