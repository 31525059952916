import axios from "axios";
import env from "@/env";
import "@/api/requests_config";

export default {
    getAccountInvoices(data) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${env.DEVELOPMENT_DOMAIN}/stripe/customer-transactions` , data)
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    sendVerify(token) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${env.DEVELOPMENT_DOMAIN}/auth/send-verify-email` , {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((data) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};
