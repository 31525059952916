
const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-home" */"@/pages/HomePage.vue"),
    meta: {
      title: 'Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/pricing",
    name: "PricesPages",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-pricing" */"@/pages/PricesPages.vue"),
    meta: {
      title: 'Artefy Pricing | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
      metaTags: [
        {
          name: 'Prices Plans',
          content: 'Learn about our Prices',
        },
      ],
    },
  },
  {
    path: "/check-out",
    name: "CheckOut",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-checkout" */"@/pages/CheckOut.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy Checkout | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-payment" */"@/pages/PaymentPage.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy Payment | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/meeting-date",
    name: "MeetingDate",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-payment" */"@/pages/MeetingDate.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy Payment | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/thank-you",
    name: "ThankYou",
    component: () => import(/* webpackChunkName: "Artef y-ecommerce-solution-thank-you" */"@/pages/ThankYouPage.vue"),
    meta: {
      requiresAuth: true,
      title: 'Thanks for choosing Artefy | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/final-step",
    name: "Final",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-purchase" */"@/pages/FinalPage.vue"),
    meta: {
      title: 'Artefy Onboarding | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/blogs",
    name: "Blog",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-blogs" */"@/pages/BlogSection/BlogPage.vue"),
    meta: {
      title: 'Artefy useful blog posts about ecommerce | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/blog-page/:slug",
    name: "BlogPost",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-blog-info" */"@/pages/BlogSection/BlogPost.vue"),
    props: true,
    meta: {
      sitemap: {
        slugs: async () => await getBlogsSlug(),
      },
      title: 'Artefy blog | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-support" */"@/pages/ContactUsPage.vue"),
    meta: {
      title: 'Artefy customer service | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-about-us" */"@/pages/AboutUs.vue"),
    meta: {
      title: 'About Us | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/refund-policy",
    name: "RefundPolicy",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-refund-policy" */"@/pages/RefundPolicyPage.vue"),
    meta: {
      title: 'Artefy Refund Policy | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-privacy-policy" */"@/pages/PrivacyPolicyPage.vue"),
    meta: {
      title: 'Artefy Privacy Policy | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/landing-login",
    name: "LandingLogin",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page-login" */"@/pages/LandingLogin.vue"),
    meta: {
      title: 'Artefy Landing Page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/landing-main",
    name: "LandingMain",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/LandingMain.vue"),
    meta: {
      title: 'Artefy Main Landing Page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/my-account",
    name: "Account",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/MyAccount.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy Account | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/LoginPage.vue"),
    meta: {
      title: 'Artefy Login | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/SignupPage.vue"),
    meta: {
      title: 'Artefy Signup | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/WelcomePage.vue"),
    meta: {
      title: 'Artefy Welcome Page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/forget-password-success",
    name: "ForgetPasswordSuccess",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/ForgetPasswordSuccess.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy password changed successfully | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/otp",
    name: "OTP",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/OtpPage.vue"),
    meta: {
      title: 'Artefy password reset page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/success-signup",
    name: "SuccessSignup",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/successSignup.vue"),
    meta: {
      title: 'Artefy password reset page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/ForgetPasswordPage.vue"),
    meta: {
      title: 'Artefy password reset | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/change-password-success",
    name: "ChangePasswordSuccess",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/ChangePasswordSuccess.vue"),
    meta: {
      title: 'Artefy password reset successfully | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {

        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword#",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/ChangePasswordPage.vue"),
    meta: {
      title: 'Artefy password reset page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/update-password",
    name: "UpdatePassword#",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/UpdatePassword.vue"),
    meta: {
      title: 'Artefy password reset page | Your Effortless Ecommerce Solution | Artefy.ai',
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/email-not-verified",
    name: "EmailNotVerified",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/EmailNotVerified.vue"),
    meta: {
      requiresAuth: true,
      title: 'Artefy email not verified | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: '/:catchAll(.*)',
    name: "404",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/404Error.vue"),
    meta: {
      title: 'Artefy | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/403",
    name: "403",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/403Error.vue"),
    meta: {
      title: 'Artefy password reset page | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/500",
    name: "500",
    component: () => import(/* webpackChunkName: "Artefy-ecommerce-solution-landing-page" */"@/pages/AccountPages/500Error.vue"),
    meta: {
      title: 'Artefy | Your Effortless Ecommerce Solution | Artefy.ai',
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#ff9124" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
];

const axios = require('axios');

function getBlogsSlug() {
  const apiUrl = 'https://api.artefy.ai/api/blogs/slugs';

  return axios.get(apiUrl)
    .then(response => {
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      return response.data;
    })
    .then(data => {
      // You can work with the data here
      let slugs = [];
      for (let i = 0; i < data.data.length; i++) {
        const newObj = {
          id: data.data[i].id,
          slug: data.data[i].slug,
          // Copy other properties if needed
        };
        slugs.push(newObj);
      }

      return slugs;
    })
    .catch(error => {
      console.error('Error:', error);
    });
}


module.exports = routes;