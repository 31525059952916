import {createRouter , createWebHistory} from "vue-router";
import  store  from './store/store'

import routes from "@/routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name === 'Login') {
    const user = JSON.parse(sessionStorage.getItem('user'))

    // Check if the user is logged in
    if (user == null) {
      // If the user is logged in, navigate to the Home route
      next();
    } else {
      // If the user is not logged in, you can redirect to a login route
      next('/'); // Change '/login' to the actual login route path
    }
  }
    // if (to.name === 'CheckOut') {
    //   if (user?.user?.store_user == null) {
    //     next();
    //   } else {
    //     next('/payment');
    //   }
    // }
  const selectPackage = store.getters.packageDetails

  if(to.name == 'ChangePassword#') {
    if (from.name == 'OTP') {
      next()
    } else {
      next('/forget-password')
    }
  }

    if (to.name == 'ChangePasswordSuccess') {
      if (from.name == 'ChangePassword#' || from.name == 'UpdatePassword#') {
        next()
      } else {
        next('/forget-password')
      }
    }
    if (to.name == 'OTP') {
      if (from.name == 'ForgetPassword') {
        next()
      } else {
        next('/forget-password')
      }
    }
    if (to.name == 'ThankYou') {
      if (from.name == 'MeetingDate') {
        next()
      } else {
        next('/')
      }
    }
    if (to.name == 'MeetingDate') {
      if (from.name == 'Payment') {
        next();
      } else {
        next('/pricing');
      }
    }
  if (to.name == 'SuccessSignup') {
    if (from.name == 'Signup') {
      next();
    } else {
      next('/sign-up');
    }
  }
    if (to.name === 'Payment') {
      // Check if the user is logged in
      if (selectPackage !== null) {
        // If the user is logged in, navigate to the Home route
        next();
      } else {
        // If the user is not logged in, you can redirect to a login route
        next('/pricing'); // Change '/login' to the actual login route path
      }
    }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = JSON.parse(sessionStorage.getItem('user'))

    // Check if the route requires authentication
    if (user !== null) {
      next(); // Proceed to the route
    } else {
      next('/login'); // Redirect to the login page
    }
  } else {
    next(); // No validation required, proceed to the route
  }
});
router.afterEach(() => {
router.afterEach((to) => {
  document.title = `${to.meta.title}`;
  window.scrollTo(0, 0);

});
})


export default router;
