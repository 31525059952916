import API from '@/api/check_out_api';

const state = {
    payload: null,
    access_payment:false,
};

const getters = {
    getAccessPayment(state){
        return state.access_payment
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    submitForm({commit}, data) {
        return new Promise((resolve, reject) => {
            API.submitForm(data).then((payload) => {
                commit('accessPayment')
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    MeetingDate({commit}, data) {
        return new Promise((resolve, reject) => {
            API.MeetingDate(data).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
};

const mutations = {
    accessPayment(state){
        state.access_payment = true
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}