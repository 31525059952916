import API from '@/api/account_api';
const state = {
    payload: null,
};

const getters = {

};

const actions = {
    // eslint-disable-next-line no-unused-vars
    accountInvoices({commit} , data){
        return new Promise((resolve, reject) => {
            API.getAccountInvoices().then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    getAccountDescription({commit} , data){
        return new Promise((resolve, reject) => {
            API.getAccountDescription().then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    verifyEmail({commit} , token){
        return new Promise((resolve, reject) => {
            API.sendVerify(token).then((payload) => {
                resolve(payload);
            }).catch((e) => {
                reject(e);
            });
        });
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}